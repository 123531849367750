var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"creation-modal",attrs:{"id":"modal-create","size":"lg","title":_vm.modalProperties.title,"hide-footer":""},on:{"show":_vm.resetModal}},[_c('ValidationObserver',{ref:"validation-observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('ValidationProvider',{attrs:{"name":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('companies.type')}},[_c('b-form-select',{staticClass:"mb-2",attrs:{"id":"roles","label-for":_vm.$t('companies.type'),"name":_vm.$t('companies.type'),"options":_vm.typeOptions},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":0,"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("companies.type_enter"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.company.type_id),callback:function ($$v) {_vm.$set(_vm.company, "type_id", $$v)},expression:"company.type_id"}})],1),(errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),_vm._l((_vm.providerSchema),function(ref){
var label = ref.label;
var labelFor = ref.labelFor;
var bind = ref.bind;
var mask = ref.mask;
var rules = ref.rules;
return _c('ValidationProvider',{key:label + labelFor,attrs:{"name":label,"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-2",attrs:{"label":label,"label-for":labelFor}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(mask),expression:"mask"}],attrs:{"id":labelFor},model:{value:(_vm.company[bind]),callback:function ($$v) {_vm.$set(_vm.company, bind, $$v)},expression:"company[bind]"}})],1),(errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})}),_c('div',{staticClass:"d-flex justify-content-end pb-4"},[_c('b-button',{attrs:{"variant":"light"},on:{"click":_vm.resetModal}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('b-button',{staticClass:"ml-1 mr-0",attrs:{"type":"submit","variant":"success"}},[(!_vm.loading)?_c('i',{staticClass:"fas fa-save"}):_vm._e(),_c('span',{staticClass:"save__button"},[_vm._v(_vm._s(_vm.$t("save")))]),(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }