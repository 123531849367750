import { render, staticRenderFns } from "./Apartments.vue?vue&type=template&id=f7531e38&scoped=true&"
import script from "./Apartments.vue?vue&type=script&lang=js&"
export * from "./Apartments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7531e38",
  null
  
)

export default component.exports