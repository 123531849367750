var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vh-100 d-flex justify-content-center align-items-center flex-column"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-5"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/img/object__img1.png"),"alt":"logo"}})])],1)]),_vm._m(0),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100"},[_c('ValidationObserver',{ref:"validation-observer"},[_c('form',{staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.submittedForm.apply(null, arguments)}}},[_c('div',{staticClass:"form"},[_vm._l((_vm.loginSchema),function(ref){
var type = ref.type;
var name = ref.name;
var rules = ref.rules;
var id = ref.id;
var label = ref.label;
var placeholder = ref.placeholder;
var bind = ref.bind;
var schemaError = ref.schemaError;
return _c('ValidationProvider',{key:name+id,attrs:{"name":name,"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(label))]),((type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user[bind]),expression:"user[bind]"}],staticClass:"form-control bg-transparent",attrs:{"placeholder":placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.user[bind])?_vm._i(_vm.user[bind],null)>-1:(_vm.user[bind])},on:{"change":function($event){var $$a=_vm.user[bind],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.user, bind, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.user, bind, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.user, bind, $$c)}}}}):((type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user[bind]),expression:"user[bind]"}],staticClass:"form-control bg-transparent",attrs:{"placeholder":placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.user[bind],null)},on:{"change":function($event){return _vm.$set(_vm.user, bind, null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user[bind]),expression:"user[bind]"}],staticClass:"form-control bg-transparent",attrs:{"placeholder":placeholder,"type":type},domProps:{"value":(_vm.user[bind])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, bind, $event.target.value)}}}),(schemaError.show && errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(" "+_vm._s(schemaError.text)+" ")]):_vm._e()])]}}],null,true)})}),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('button',{staticClass:"btn btn-primary mr-0 w-100",class:{'button-disabled':_vm.loading},attrs:{"type":"submit"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("auth.login"))+" ")]),(_vm.loading)?_c('span',{staticClass:"spinner"}):_vm._e()])])],2)])])],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n          col-md-8\n          d-none d-md-flex\n          justify-content-center\n          align-items-center\n        "},[_c('div',{staticClass:"rounded overflow-hidden"},[_c('img',{staticClass:"mw-100",attrs:{"src":require("@/assets/img/login-img2.svg"),"alt":"login img"}})])])}]

export { render, staticRenderFns }