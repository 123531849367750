import apartments from "@/services/apartments";
import branches from "@/services/branches";
import objects from "@/services/objects";
import user from "@/services/user"
import companies from "@/services/companies";
import contract from "@/services/contract";

export default {
    user,
    objects,
    branches,
    companies,
    apartments,
    contract
}