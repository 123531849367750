var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user__content"},[_c('div',{staticClass:"avatar__content"},[_c('div',{staticClass:"avatar__content-icon"},[(_vm.loadingProgress)?[_c('svg',{staticClass:"progress__front",attrs:{"stroke-linecap":"round","stroke-width":3,"stroke-dashoffset":_vm.calcValue,"stroke":"#3480eb","viewBox":"0 0 100 100"}},[_c('circle',{attrs:{"cx":"50","cy":"50","r":"45"}})]),_c('span',{staticClass:"progress__counter"},[_vm._v(_vm._s(_vm.loadingProgress)+" %")])]:_vm._e(),_c('div',{staticClass:"upload__content"},[_c('b-avatar',{staticClass:"avatar-wrapper",class:{'active-loading':_vm.loadingProgress},attrs:{"variant":"primary","src":_vm.getUserAvatarUrl,"text":_vm.getNameSnippet,"size":"6rem"}}),_c('input',{staticClass:"upload__avatar",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.compressImage}})],1)],2),_c('div',{staticClass:"editor__content"},[_c('div',{staticClass:"editor__content-buttons"},[_c('div',{staticClass:"upload__content"},[_c('b-button',{staticClass:"submit__button",attrs:{"variant":"primary"}},[_vm._v("Upload Photo")]),_c('input',{ref:"upload-image",staticClass:"upload__avatar",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.compressImage}})],1)])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"user__form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitUserInfo)}}},_vm._l((_vm.formSchema),function(ref){
var type = ref.type;
var name = ref.name;
var rules = ref.rules;
var bind = ref.bind;
var placeholder = ref.placeholder;
var extraClass = ref.extraClass;
var id = ref.id;
return _c('ValidationProvider',{key:name+rules,class:extraClass,attrs:{"name":name,"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":id}},[_vm._v(_vm._s(name))]),_c('b-form-input',{attrs:{"type":type,"id":id,"placeholder":placeholder,"disabled":""},model:{value:(_vm.user[bind]),callback:function ($$v) {_vm.$set(_vm.user, bind, $$v)},expression:"user[bind]"}}),_c('span',{staticClass:"error__provider"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})}),1)]}}])}),_c('base-loading-content',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }