var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{staticClass:"overlay__alert",attrs:{"show":_vm.loading,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"3","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Пожалуйста подождите...")])],1)]},proxy:true}])},[_c('b-alert',{staticClass:"success__alert",attrs:{"dismissible":"","variant":_vm.responseAlert.variant,"show":_vm.responseAlert.dismissCountDown},on:{"dismissed":_vm.dismissedAlert,"dismiss-count-down":_vm.countDownChanged}},[_c('p',[_vm._v(_vm._s(_vm.responseAlert.message))]),_c('b-progress',{attrs:{"variant":_vm.responseAlert.variant,"max":_vm.responseAlert.dismissSecs,"value":_vm.responseAlert.dismissCountDown,"height":"4px"}})],1),_c('div',{attrs:{"aria-hidden":_vm.hiddenArea}},[_c('ValidationObserver',{ref:"validation-observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form__password",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitNewPassword)}}},[_vm._l((_vm.providerSchema),function(ref,index){
var type = ref.type;
var name = ref.name;
var rules = ref.rules;
var extraClass = ref.extraClass;
var id = ref.id;
var label = ref.label;
var placeholder = ref.placeholder;
var bind = ref.bind;
var validationError = ref.validationError;
return _c('ValidationProvider',{key:name+id,staticClass:"mt-3",class:extraClass,attrs:{"name":name,"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":id}},[_vm._v(_vm._s(label))]),_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"toggle__password__view",on:{"click":function($event){return _vm.toggleInputType(index)}}},[(type === 'password')?_c('span',[_c('img',{attrs:{"src":require("@/assets/icons/no-preview-aye.svg"),"alt":"no-preview-aye.svg"}})]):_c('span',[_c('img',{attrs:{"src":require("@/assets/icons/preview-aye.svg"),"alt":"preview-aye.svg"}})])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":type,"id":id,"placeholder":placeholder},model:{value:(_vm.form[bind]),callback:function ($$v) {_vm.$set(_vm.form, bind, $$v)},expression:"form[bind]"}})],1),(errors[0])?_c('span',{staticClass:"error__provider"},[_vm._v(_vm._s(_vm.$t('user.validation_password')))]):_vm._e(),(validationError.show)?_c('span',{staticClass:"error__provider"},[_vm._v(_vm._s(validationError.message))]):_vm._e()]}}],null,true)})}),_c('div',{staticClass:"buttons"},[_c('b-button',{staticClass:"submit__button",attrs:{"disabled":_vm.loading,"type":"submit","variant":"btn-primary"}},[_vm._v(" Обновить пароль ")])],1)],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }